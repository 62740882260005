<template>
  <b-modal no-fade v-model="showDialog" size="lg" :ref="modalId" :id="modalId" :hide-footer="true"
           no-close-on-backdrop>
    <div>
      <div v-if="loading === false">
        <h3 class="text-right mb-5">{{msg("Order")}} #{{id}}</h3>
        <order-panel v-model="order" v-bind:client="client"/>
        <div class="mt-2 text-center">
          <b-button variant="primary" class="mx-1" @click="save">{{msg("Save")}}</b-button>
        </div>
      </div>
      <div v-else-if="this.error != null">
        <b-alert show variant="danger"><i class="fas fa-exclamation-triangle mr-3"></i>
          <strong>{{ error }}</strong>
        </b-alert>
      </div>
      <div v-else>
        <b-spinner label="Spinning"/>
        {{ msg('loading') }}
      </div>
    </div>

  </b-modal>
</template>

<script>
import { mapActions } from 'vuex'
import OrderPanel from '@/components/panels/order/orderPanel'

export default {
  name: 'orderModal',
  components: { OrderPanel },
  data:()=>({
    uniqueId: 0,
    loading: true,
    error: null,
    order: {},
    client: {},
  }),
  props: {
    id: {
      required: true,
    },
    dialog: {
      required: true,
      default: false,
      type: Boolean
    },
  },
  watch: {
    id: function () {
      this.error = null
      this.onLoad()
    },
  },
  created() {
    this.uniqueId = this._uid
    this.onLoad()
  },
  computed:{
    modalId() {
      return 'client___modal___' + this.uniqueId
    },
    showDialog: {
      get() {
        return this.dialog === true
      },
      set(val) {
        this.$emit('update:dialog', (val === true))
      }
    },
  },
  methods:{
    ...mapActions('order',['get','update']),
    onLoad(){
      this.loading = true;
      this.error = null;
      this.get({id:this.id}).then(this.onOrderLoaded,this.onError)
    },
    onOrderLoaded({ order,client }){
      this.loading = false;
      this.order=order;
      this.client=client;
    },
    onError(error){
        this.error = error;
    },
    onCancel(){
      this.showDialog=false
    },
    save(){
      let $this = this;
      this.update({id:this.id,order:this.order}).then(()=>{
        $this.$root.$emit('crm::order::update');
        $this.onLoad();
      })
    }
  }
}
</script>

<style scoped>

</style>
