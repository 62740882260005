<template>
  <div>
    <b-table
        ref="order-table"
        striped
        hover
        responsive
        v-bind:items="orders"
        v-bind:fields="fields"
        :busy="loading"
        @sort-changed="onSort"
        @row-clicked="onRowClick"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mx-1"></b-spinner>
          <strong>{{ msg('Loading') }}...</strong>
        </div>
      </template>
      <template #cell(id)="data" class="p-0">
        <p  v-b-tooltip :title="data.value">{{data.value}}</p>
      </template>
      <template #cell(total)="data" class="p-0">
        <p  v-b-tooltip :title="data.value">{{(data.value).toFixed(2)}}</p>
      </template>
      <template  #cell(clientId)="data">
        <div v-if="clients[data.value] != null">
          <b-link @click="onClientSelect(clients[data.value].id)">{{ clients[data.value].firstname }} {{clients[data.value].lastname}}</b-link>
        </div>
        <div v-else>
          <span v-if="orders[data.index].shipping!=null && orders[data.index].shipping.phone!=null">{{msg('Phone')}}: {{orders[data.index].shipping.phone}},</span>
          <span v-if="orders[data.index].shipping!=null && orders[data.index].shipping.email!=null"> {{msg('Email')}}: {{orders[data.index].shipping.email}}</span>
        </div>
      </template>
      <template #cell(creationDate)="data">
        {{ $moment(data.value,'YYYY-MM-DD HH:mm').format("DD/MM/YY HH:mm") }}
      </template>
      <template #cell(status)="data" class="p-0">
        <b-badge :variant="statusVariants[data.value]">{{data.value}}</b-badge>
      </template>
    </b-table>
    <!--Table Paginator-->
    <div class="text-center w-100">
      <div class="d-inline-block">
        <b-pagination
            v-model="page.currentPage"
            :total-rows="page.total"
            :per-page="page.amount"
            first-number
            last-number
            @input="refresh"
        />
      </div>
      <div class="d-inline-block px-2"><strong>{{ msg('Total') }}</strong> {{ page.total }}</div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'OrderTable',
  data: () => ({
    loading: true,
    page: { amount: 10, currentPage: 1, total: 0 },
    orders: [],
    clients:{},
    sort: { by: 'creationDate', direction: 'desc', },
    statusVariants:
        {"NEW":"primary","CASH_OPEN":"primary","PENDING":"warning","SHIPPED":"success",
          "PAID":"success","PAID_CASH":"success","WAITING_FOR_PAYMENT":"warning","PAYMENT_FAILED":"danger","CANCELLED":"danger","COMPLETED":"success"},
    //Listeners
    listeners: { refresh: () => {} }
  }),
  props: {
    filter: {
      default: () => {},
    },
    fieldsFilter: {
      default: () => [],
    }
  },
  watch:{
    filter: {
      deep: true,
      handler() {
        this.refresh()
      }
    }
  },
  created() {
    let $this = this;
    this.refresh();
    this.$root.$on('crm::branch-updated', (this.listeners.refresh = () => {$this.refresh()}))
    this.$root.$on('crm::order::update', this.listeners.refresh)
  },
  beforeDestroy() {
    this.$root.$off('crm::branch-updated', this.listeners.refresh)
    this.$root.$off('crm::order::update', this.listeners.refresh)
  },
  computed: {

    fields() {
      let $this = this
      let fields = [
        {
          key: 'id',
          label: $this.msg('id'),
          sortable: true
        },
        {
          key: 'creationDate',
          label: $this.msg('creationDate'),
          sortable: true
        },
        {
          key: 'clientId',
          label: $this.msg('client'),
          sortable: false
        },

        {
          key: 'total',
          label: $this.msg('total'),
          sortable: true
        },

        {
          key: 'status',
          label: $this.msg('status'),
          sortable: true
        },

      ]
      if (!Array.isArray(this.fieldsFilter) || this.fieldsFilter.length === 0) {
        return fields
      }
      return fields.filter(f => $this.fieldsFilter.includes(f.key))
    },
  },
  methods: {
    ...mapActions('order', ['getOrders']),
    ...mapActions('clients', ['getClients']),
    refresh() {
      this.loading = true;
      this.getOrders({
        page: this.page.currentPage,
        amountPerPage: this.page.amount,
        filter: this.filter,
        sortby: this.sort.direction,
        sortcolumn: this.sort.by
      }).then(this.onData, this.onDataError)
    },
    onData({ orders, amountPerPage, total, page }) {
      this.orders = orders;
      this.page.total = total;
      this.page.currentPage = page;
      this.page.amount = amountPerPage;
      this.loadClients();
      this.loading = false;
    },
    onDataError(err) {

    },
    loadClients(){
      let $this = this;
      let clients = this.orders.map(order=>order.clientId)
      this.getClients({ filter:{ids:clients},amountPerPage:9999}).then(({clients})=>{
        $this.clients = clients.toObject("id");
      })
    },
    updatePageAmount(amount){
      this.page.amount = amount;
      this.refresh();
    },
    onSort(data) {
      this.sort.by = data.sortBy
      this.sort.direction = data.sortDesc ? 'desc' : 'asc'
      this.refresh()
    },
    onRowClick(order) {
      this.$emit("rowClicked", order)
    },
    onClientSelect(clientId) {
      this.$root.$emit('bv::hide::popover')
      this.$root.$emit('crm::client::select',clientId)
    },
  }
}
</script>

<style scoped>

</style>
