<template>
  <div class="">
    <!--Filters-->
    <b-card no-body>
      <b-card-header class="pb-50">
        <b-row no-gutters class="w-100">
          <b-col>
            <h5>{{ msg('Filters') }}</h5>
          </b-col>
          <b-col>
            <div class="d-flex justify-content-end">
              <b-link style="color: inherit" @click="refresh()">
                <feather-icon icon="RefreshCcwIcon" size="16"/>
              </b-link>
            </div>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>
          <!-- First name-->
          <b-col cols="12" md="6" class="mb-md-0 mb-2">
            <label>{{ msg('Id') }}</label>
            <b-form-input
                v-model="filter.like.id"
                class="d-inline-block mr-1"
            />
          </b-col>
          <!-- Country-->
          <b-col cols="12" md="6" class="mb-md-0 mb-2">
            <label>{{ msg('Status') }}</label>
            <v-select
                :dir="isRTL ? 'rtl' : 'ltr'"
                v-model="filter.in.status"
                :options="orderStatus"
                class="w-100"
                label="text"
                :reduce="(status) => status.value"
                multiple
            />
          </b-col>
<!--          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{ msg('Creation Time') }}</label>
            <VueCtkDateTimePicker :button-now-translation="msg('Now')" range no-shortcuts
                                  v-model="creationDatePicker"
                                  :locale="currentFormattedLang" :only-date="true"
                                  format="YYYY-MM-DD" formatted="ll" no-header
            >
            </VueCtkDateTimePicker>
          </b-col>-->
        </b-row>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <div class="card-header" style="overflow: hidden">
        <span class="card-title m-0">{{ msg('Orders') }}</span>
        <b-col>
          <div class="d-flex justify-content-end">
            <b-link style="color: inherit" @click="()=>$refs['orderTable'].refresh()">
              <feather-icon icon="RefreshCcwIcon" size="16"/>
            </b-link>
          </div>
        </b-col>
      </div>
      <!--Card Content-->
      <b-card-body>
        <div class="">
          <!-- Table Top -->
          <b-row no-gutters class="mb-1">
            <!-- Per Page -->
            <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label>{{ msg('Show') }}</label>
              <v-select
                  v-model="amountPerPage"
                  :dir="isRTL ? 'rtl' : 'ltr'"
                  :options="[10, 25, 50, 100]"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                  @input="(amount)=>$refs['orderTable'].updatePageAmount(amount)"
              />
              <label>{{ msg('entries') }}</label>
            </b-col>
            <!-- Search -->
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                    v-model="filter.search"
                    class="d-inline-block"
                    :placeholder="msg('Search')"
                />
                <span class="mr-1"></span>
              </div>
            </b-col>
          </b-row>
          <order-table ref="orderTable" v-bind:filter="filter" @rowClicked="onRowClicked"/>
        </div>
      </b-card-body>
    </b-card>
    <order-modal v-bind:dialog.sync="dialogs.order" v-bind:id="selected"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import OrderTable from '@/components/tables/OrderTable'
import OrderModal from '@/components/modals/orderModal'

export default {
  name: 'Order',
  components: {
    OrderModal,
    OrderTable,
  },
  data() {
    return {
      filter: {search: '', like: {id: ""}, eq: {type: ""}, lte: {}, gte: {},in:{status:[]}},

      amountPerPage: 10,
      dialogs: { order: false },
      selected:"",
    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.refresh()
      }
    }
  },
  computed: {
    ...mapGetters('order', ['allOrderStatus']),
    orderStatus() {
      return this.allOrderStatus.map(status => ({
        value: status.value,
        text: this.msg(status.text)
      }))
    },
    creationDatePicker: {
      get() {
        let $this = this
        return {
          start: $this.filter.gte.creationDate,
          end: $this.filter.lte.creationDate
        }
      },
      set(v) {
        console.log('creationDatePicker', v)
        if (v == null) {
          this.filter.gte.creationDate = null
          this.filter.lte.creationDate = null
        }
        this.filter.gte.creationDate = v.start
        this.filter.lte.creationDate = v.end
      }
    },

  },

  methods: {
    onRowClicked(order){
      this.selected=order.id;
      this.dialogs.order = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
