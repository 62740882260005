<template>
  <div>
    <b-row no-gutters class="mb-2">
      <b-col cols="4">
        <h5 class="mb-2">{{ msg('Client') }}</h5>
        <div v-if="hasClient">
          <h6 class="mb-25 text-capitalize">{{ client.firstname }} {{ client.lastname }}</h6>
          <p class="card-text mb-0">{{ shippingAddress.email || client.email }}</p>
          <p class="card-text mb-0">{{ client.phone }} / {{ client.mobile }}</p>
        </div>
        <div v-else>
          <p class="card-text mb-0">{{ shippingAddress.email }}</p>
          <p class="card-text mb-0">{{ shippingAddress.phone }}</p>
        </div>
      </b-col>
      <!-- Col: Invoice To -->
      <b-col cols="4">
        <h5 class="mb-2">{{ msg('Address') }}</h5>
        <p class="card-text mb-25" v-if="shippingAddress.address != null">
          {{ shippingAddress.address }},
          <span v-if="shippingAddress.zip != null">{{ shippingAddress.zip }},</span> {{ shippingAddress.city }}
        </p>
        <p class="card-text mb-25" v-if="shippingAddress.country != null">
          {{ getCountry(shippingAddress.country).name }}
        </p>
      </b-col>
      <b-col cols="4">
        <h5 class="mb-2">{{ msg('Total') }}</h5>
        <p class="mb-0"><b>{{ msg("Original price") }}:</b> {{ (order.originalTotal || 0).toFixed(2) }}</p>
        <p class="mb-0"><b>{{ msg("Final price") }}:</b> {{ (order.total || 0).toFixed(2) }}</p>
      </b-col>
    </b-row>
    <!-- Products-->
    <b-table-lite
        responsive
        :items="order.products"
        :fields="[{ key: 'productName', label: msg('product')},{ key: 'amount', label: msg('amount')}, { key: 'primaryPrice', label: msg('price')}, { key: 'price', label: msg('final Price')}]"
    >
      <template #cell(productName)="data">
        <b-link @click="$router.push('/product/edit/'+data.item.productId)">{{ data.value }}</b-link>
      </template>
      <template #cell(primaryPrice)="data">
        {{ data.value == null || data.value === 0 ? data.item.price : data.value }}
      </template>
    </b-table-lite>
    <!-- Status-->
    <div>
      <label>{{ msg("Note") }}</label>
      <b-textarea v-model="order.note"></b-textarea>
    </div>
    <hr/>



    <div class="text-center px-2 ">
      <div class="d-inline-block m-0">
        <b-button v-b-toggle.collapse-1 variant="primary" class="mx-1">
          {{ msg("Status history timeline") }}
        </b-button>
      </div>
      <h5 class="d-inline-block my-0 mx-2">{{ msg("Status") }}:</h5>
      <div class="d-inline-block m-0">
        <b-form-select
            style="min-width: 300px;text-align: center"
            :dir="isRTL ? 'rtl' : 'ltr'"
            :clearable="false"
            v-model="order.status"
            :options="allOrderStatus"
            class="w-100"/>
      </div>
    </div>

    <div>
      <b-collapse id="collapse-1" class="mt-2">
        <b-card  v-if="order.histories!=null && order.histories.length>0">
          <app-timeline v-for="(statusHistory,index) in order.histories" v-bind:key="index">

            <app-timeline-item class="mb-2"
                :title="'Status: '+statusHistory.status"
                :subtitle="'Source: '+ statusHistory.source + (statusHistory.userId>0? (', User: '+ getUserFullName(statusHistory.userId)):'')"
                :time="statusHistory.time | moment('DD/MM/YY HH:mm')"
                :variant="statusVariants[statusHistory.status]"
            />
          </app-timeline>
        </b-card>
      </b-collapse>

    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  name: 'orderPanel',
  props: {
    value: {
      required: true
    },
    client: {}
  },
  data:()=>({
    statusVariants:
        {"NEW":"primary","CASH_OPEN":"primary","PENDING":"warning","SHIPPED":"success",
          "PAID":"success","PAID_CASH":"success","WAITING_FOR_PAYMENT":"warning","PAYMENT_FAILED":"danger","CANCELLED":"danger","COMPLETED":"success"},

  }),
  computed: {
    ...mapGetters('order', ['allOrderStatus']),
    ...mapGetters('data', ['getCountry']),
    ...mapGetters('data', ['getUserFullName', 'getUserAcronyms']),

    order: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },
    hasClient() {
      return this.client != null;
    },
    shippingAddress() {
      if (this.order.shipping == null) {
        return {}
      }
      return this.order.shipping
    },

  },
  methods: {
    ...mapGetters('order', ['allFinalStatus']),
    isFinal(status) {
      return this.allFinalStatus().includes(status)

    }
    , getSource(statusHistory) {
      if (statusHistory.source==='Crm'){
        return 'Source: '+ statusHistory.source +' User: '+ this.getUserAcronyms(statusHistory.user);
      }
      else return 'Source: '+statusHistory.source;
    },

  }
}
</script>

<style scoped>

</style>
